<template>
	<div id="content" class="container-fluid">
		<!-- Header de la vue, avec son titre -->
		<HeaderTab :title="title" :return_action="'back_action'" />

		<label>{{ title }}</label>

		Fonctionnalité bientot disponible.
	</div>
</template>


<script type="text/javascript">
	import HeaderTab from '@/components/HeaderTab'
	import Tools from '@/mixins/Tools.js'
	import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "Recherche",
		mixins: [
			Tools,
			Navigation
		],
		components: {
			HeaderTab
		},
		methods: {
			back_action() {
				this.goBack()
			}
		},
		computed: {
			title: function() {
				return this.getTrad('global.recherche')
			}
		}
	}
</script>
